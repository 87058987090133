import { Client } from '../setup/Client';
import { Layout } from '../setup/Layout';
import axiosInstance from './axios';
import * as appConstants from './const';
import {
  Macs,
  SampleAmplusAppDetails,
  SampleBGPNAppDetails,
  SampleBGPNAppDetails2,
  SampleTpnAppDetails,
} from './const';
import i18n from '../i18n/i18n';

let loadedDefaultLanguage = false;

export const getAppInfo = async (): Promise<AppDetails> => {
  const appDetailsLs = GetFromLocalStorage();
  const appIsNotExpired = appDetailsLs !== null && appDetailsLs.expireOn && Date.now() < appDetailsLs.expireOn;
  if (appDetailsLs !== null && appIsNotExpired) {
    console.log('Getting AppDetails from sessionStorage');
    return appDetailsLs;
  }
  console.log(
    'Window size w:' +
      window.screen.width +
      ' h: ' +
      window.screen.height +
      ' aw: ' +
      window.screen.availWidth +
      ' ah: ' +
      window.screen.availHeight +
      ' pd: ' +
      window.screen.pixelDepth +
      ' dpi: ' +
      window.devicePixelRatio
  );

  const mac = await getDeviceMac();
  try {
    if (window.BtDevice) {
      console.log('Getting AppDetails from API');
      const result = await axiosInstance.get<AppDetails>('v1/paybox/application-details?mac=' + mac);
      console.log('App details get from api: ' + JSON.stringify(result.data));
      await setLanguage(result.data.language);
      SaveToLocalStorage(result.data);
      return result.data;
    } else {
      console.log('Getting TEST AppDetails');
      const details = appConstants.SampleTpnAppDetails;
      await setLanguage(details.language);
      SaveToLocalStorage(details);
      return details;
    }
  } catch (error) {
    console.error('Failed getting device info: ' + JSON.stringify(error));
    if (appDetailsLs !== null) {
      return appDetailsLs;
    } else {
      return {} as AppDetails;
    }
  }
};

const setLanguage = async (language: string) => {
  if (loadedDefaultLanguage || i18n.language == language) {
    return;
  }

  console.log(`Setting initial language.`, language);
  await i18n.changeLanguage(language);
  loadedDefaultLanguage = true;
};

export const getEncodedImage = (qrCode): string => {
  try {
    return window.BtDevice.generateQR(qrCode);
  } catch (e) {
    console.error('Failed generating QR', JSON.stringify(e));
    return '';
  }
};

export const getDeviceMac = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      if (window.BtDevice) {
        const response = window.BtDevice.getDeviceMac();
        console.log('[TERMINAL] Terminal - getDeviceMac - Success:', response);
        resolve(response);
      } else {
        resolve(Macs.PngsWithPrinter);
        console.log('[TERMINAL][Local] Terminal - getDeviceMac - Success:');
      }
    } catch (error) {
      console.error('[TERMINAL] Terminal - getDeviceMac - Error:', JSON.stringify(error));
      reject('ERROR_MAC');
    }
  });
};

const GetFromLocalStorage = (): AppDetails | null => {
  const appInfoStr = sessionStorage.getItem(appConstants.AppInfoKey);
  if (appInfoStr == null) {
    console.log("AppDetails doesn't exist in sessionStorage");
    return null;
  }
  const appInfo = JSON.parse(appInfoStr) as AppDetails;
  if (appInfo.expireOn == null) {
    console.log('ExpireOn was empty');
    return null;
  }
  // if (Date.now() > appInfo.expireOn) {
  //   console.log('AppDetails expired');
  //   sessionStorage.removeItem(appConstants.AppInfoKey);
  //   return null;
  // }
  return appInfo;
};

function SaveToLocalStorage(appDetails: AppDetails) {
  const expireOn = new Date(Date.now() + 300 * 1000).getTime();
  appDetails.expireOn = expireOn;
  // console.log('Saving AppDetails to sessionStorage : ' + JSON.stringify(appDetails));
  sessionStorage.setItem(appConstants.AppInfoKey, JSON.stringify(appDetails));
}

export interface AppDetails {
  id: number;
  name: string;
  deviceName: string;
  appType: string;
  language: string;
  languageVersions: string[];
  devices: DeviceDetails[];
  expireOn?: number | null;
  client: Client;
  layout: Layout;
  enabledFeatures: AppFeature[];
  hotspotRequired: boolean;
  fiscalPrinterRequired: boolean;
  acceptedPaymentTypes: AppPaymentType[];
  embeddedQrScanner: boolean;
  embeddedQrScannerLocation: ScannerLocation;
  embeddedQrScannerType: ScannerType;
  embeddedLprScanner: boolean;
  embeddedQrScannerVerticalLocation: ScannerVerticalLocation;
  embeddedQrScannerIconSize: ScannerIconSize;
  primaryMode: PrimaryMode;
  paymentProvider: PaymentProvider;
  disableAppAnimations?: boolean;
  operatorLoginEnabled: boolean;
  selfCheckout: boolean;
  lastUpdateDate?: string | null;
}

export interface DeviceDetails {
  id: number;
  name: string;
  type: string;
  ip: string;
  port: string;
  function: string;
  additionalSettings: object;
}

export enum AppFeature {
  TicketSale = 'ticketSale',
  TicketSearchByCarPlates = 'ticketSearchByCarPlates',
  TicketScan = 'ticketScan',
  ProductSale = 'productSale',
}

export enum AppPaymentType {
  CreditCard = 'credit_card',
  Cash = 'cash',
}

export enum PrimaryMode {
  None = 'none',
  Sales = 'sales',
  TicketScan = 'ticketScan',
  ProductSale = 'productSale',
}

export enum PaymentProvider {
  PayTel = 'payTel',
  VivaWallet = 'vivaWallet',
  Adyen = 'adyen',
  WorldLine = 'worldLine',
  SoftPay = 'softPay',
  EService = 'eService',
}

export enum ScannerLocation {
  right = 'right',
  left = 'left',
  top = 'top',
  bottom = 'bottom',
  none = 'none',
}

export enum ScannerVerticalLocation {
  top = 'top',
  middle = 'middle',
  bottom = 'bottom',
}

export enum ScannerIconSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum ScannerType {
  qr = 'qr',
  barcode = 'barcode',
  all = 'all',
  none = 'none',
}
